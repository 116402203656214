body {
  background: #f5f5f5;
  padding-top: 65px;
}

.btn-outline-dark, .btn-outline-light {
  border-width: 2px;
  font-weight: 500;
}

.img-fluid {
  width: 100%;
}

.img-thumbnail {
  padding: .75rem;
  background-color: #ffffff17;
  border: 1px solid #dee2e62b;
}

blockquote.blockquote {
  border-right: 4px solid #ffc108;
  background: #eee;
  padding: 1em 1.4em;
}

blockquote.blockquote p {
  font-style: italic;
}

blockquote.blockquote p:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f10d";
  font-weight: 900;
  padding: 0 8px 0 0;
  position: relative;
  top: -5px;
  color: #ffc108;
}

blockquote.blockquote .avatar {
  width: 50px;
  float: right;
  margin-left: 8px;
}

blockquote.blockquote footer.blockquote-footer {
  padding: 6px 0;
}

blockquote.blockquote footer.blockquote-footer cite {
  display: block;
}

.btn-round {
  border-radius: 20px;
}

a.navbar-brand img {
  max-width: 200px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.jumbotron {
  border-radius: 0;
}

.jumbotron h4 {
  text-shadow: 1px 1px 0 #d48a02;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section.img-bg {
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

section.img-bg .container {
  text-shadow: 1px 1px 0 #0000007d;
  text-align: center;
  padding: 150px 0;
}

section.img-bg.responsive {
  background-image: url(/img/bg-imgs/responsive-dark.jpg);
}

section.img-bg.adwords {
  background-image: url(/img/bg-imgs/adwords-yellow.jpg);
  background-position: center top;
}

section.img-bg.search {
  background-image: url(/img/bg-imgs/search-dark.jpg);
  background-position: top center;
}

section.img-bg.social {
  background-image: url(/img/bg-imgs/social-yellow.jpg);
}

section.img-bg.video {
  background-image: url(/img/bg-imgs/video-dark.jpg);
}

@media screen and (max-width: 768px) {
  blockquote.blockquote {
    border-top: 4px solid #ffc108;
    border-right: none;
    margin: 0 -15px;
  }
  .jumbotron {
    text-align: center;
  }
  .jumbotron h1 {
    font-size: 1.8em;
  }
  .jumbotron h4 {
    font-size: 1.1em;
    line-height: 1.2em;
  }
  .video-container {
    margin-bottom: 30px;
  }
  section.img-bg .container {
    padding: 60px 25px;
  }
  .display-4 {
    font-size: 2rem;
  }
  .no-gutters img {
    padding: 0 5px;
  }
}
